'use strict';

app.controller('EmployeeController', function($scope, $stateParams, TimeService, EmployeeService) {
    // Set default to current week
    $scope.date = {startDate: moment().startOf('week'), endDate: moment().endOf('week')};

    // Get new values from database
    TimeService.get('employees', $stateParams.id, $scope.date.startDate.format('YYYY-MM-DD'), $scope.date.endDate.format('YYYY-MM-DD'))
    .then(function(response) {
        $scope.times = response;
    });

    // Activate function when date changes
    $scope.$watch('date', function(newVal, oldVal){
        if (newVal === oldVal) { return; }
        TimeService.get('employees', $stateParams.id, $scope.date.startDate.format('YYYY-MM-DD'), $scope.date.endDate.format('YYYY-MM-DD'))
        .then(function(response) {
            $scope.times = response;
        });
    }, true);

    EmployeeService.get($stateParams.id).then(function(response){
        $scope.employee = response.data;
    });
});

'use strict';

app.controller('EmployeesController', function($scope, $location, EmployeeService) {
    EmployeeService.get().then(function(response){
        $scope.employees = response.data;
    });

    $scope.goTo = function(address) {
        $location.path(address);
    };
});

'use strict';

app.controller('OrdersController', function($scope, $location, OrderService){
    OrderService.get().then(function(response){
        $scope.orders = response.data;
    });

    $scope.goTo = function(address) {
        $location.path(address);
    };
});

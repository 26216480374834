'use strict';

var app = angular.module('healthyGulpAngularApp', ['ui.router', 'daterangepicker', 'xeditable']);

app.config(function($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise('/');

    $stateProvider
    .state('home', {
        url: '/',
        templateUrl: 'components/home/home.html',
        controller: 'HomeController'
    })
    .state('time', {
        url: '/time/employee/:id',
        templateUrl: 'components/time/employee.html',
        controller: 'TimeController'
    })
    .state('admin', {
        url: '/admin',
        templateUrl: 'components/admin/admin.html',
        controller: 'AdminController'
    })
    .state('admin/add/employee', {
        url: '/admin/add/employee',
        templateUrl: '/components/admin/add-employee.html',
        controller: 'AdminController'
    })
    .state('admin/add/order', {
        url: '/admin/add/order',
        templateUrl: '/components/admin/add-order.html',
        controller: 'AdminController'
    })
    .state('admin/employees', {
        url: '/admin/employees',
        templateUrl: 'components/admin/employees/employees.html',
        controller: 'EmployeesController'
    })
    .state('admin/employee', {
        url: '/admin/employees/:id',
        templateUrl: 'components/admin/employees/employee.html',
        controller: 'EmployeeController'
    })
    .state('admin/orders', {
        url: '/admin/orders',
        templateUrl: 'components/admin/orders/orders.html',
        controller: 'OrdersController'
    })
    .state('admin/order', {
        url: '/admin/orders/:id',
        templateUrl: 'components/admin/orders/order.html',
        controller: 'OrderController'
    });

});

// Controlls all requests from and to employee table
app.service('EmployeeService', function($http, $q) {
    var EmployeeService = {};

    function handleError(response){
        // The API response from the server should be returned in a
        // nomralized format. However, if the request was not handled by the
        // server (or what not handles properly - ex. server error), then we
        // may have to normalize it on our end, as best we can.
        if (!angular.isObject(response.data) || !response.data.message) {
            return($q.reject('An unknown error occurred.'));
        }
        // Otherwise, use expected error message.
        return($q.reject(response.data.message));
    }
    // I transform the successful response, unwrapping the application data
    // from the API response payload.
    function handleSuccess(response) {
        return(response.data);
    }

    EmployeeService.get = function(id) {
        var params = {};

        if(id) { params = { id:id }; }
        var request = $http({
            method: 'get',
            url: 'http://jabekon.nl/urenregistratie/api/get/employees.php',
            params: params
        });

        return(request.then(handleSuccess, handleError));
    };

    EmployeeService.set = function(data) {
        var request = $http({
            method: 'POST',
            url: 'http://jabekon.nl/urenregistratie/api/set/employee.php',
            data: {
                firstname: data.firstname,
                lastname: data.lastname,
                username: data.username,
                email: data.email
            },
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        });

        return(request.then(handleSuccess, handleError));
    };

    return EmployeeService;
});

// Controls all request to and from the timetrack table
app.service('TimeService', function($http, $q) {
    var TimeService = {};

    function handleError(response){
        // The API response from the server should be returned in a
        // nomralized format. However, if the request was not handled by the
        // server (or what not handles properly - ex. server error), then we
        // may have to normalize it on our end, as best we can.
        if (!angular.isObject(response.data) || !response.data.message) {
            return($q.reject('An unknown error occurred.'));
        }
        // Otherwise, use expected error message.
        return($q.reject(response.data.message));
    }
    // I transform the successful response, unwrapping the application data
    // from the API response payload.
    function handleSuccess(response) {
        return(response.data);
    }

    TimeService.get = function(type, id, dateStart, dateEnd) {
        var request = $http({
            method: 'get',
            url: 'http://jabekon.nl/urenregistratie/api/get/time.php',
            params: {
                type: type,
                id: id,
                dateStart: dateStart,
                dateEnd: dateEnd
            }
        });
        return(request.then(handleSuccess, handleError));
    };

    TimeService.set = function() {
    };

    return TimeService;
});

// Controls all request to and from the timetrack table
app.service('OrderService', function($http, $q) {
    var OrderService = {};

    function handleError(response){
        // The API response from the server should be returned in a
        // nomralized format. However, if the request was not handled by the
        // server (or what not handles properly - ex. server error), then we
        // may have to normalize it on our end, as best we can.
        if (!angular.isObject(response.data) || !response.data.message) {
            return($q.reject('An unknown error occurred.'));
        }
        // Otherwise, use expected error message.
        return($q.reject(response.data.message));
    }
    // I transform the successful response, unwrapping the application data
    // from the API response payload.
    function handleSuccess(response) {
        return(response.data);
    }

    OrderService.get = function(id) {
        var params = {};

        if(id) { params = { id:id }; }
        var request = $http({
            method: 'get',
            url: 'http://jabekon.nl/urenregistratie/api/get/orders.php',
            params: params
        });
        return(request.then(handleSuccess, handleError));
    };

    OrderService.set = function(data) {
        console.log(data);
        var request = $http({
            method: 'POST',
            url: 'http://jabekon.nl/urenregistratie/api/set/order.php',
            data: {
                orderNr: data.ordernr,
                customerId: data.customerId,
                description: data.description,
                status: data.status,
                color: data.color
            },
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        });
        return(request.then(handleSuccess, handleError));
    };

    return OrderService;
});

'use strict';

app.controller('TimeController', function($scope, $stateParams, $element, $http, $location, $timeout, EmployeeService, OrderService, TimeService){
    $scope.date = moment().format('D-MM-YYYY');
    $scope.week = moment().format('ww');
    
    EmployeeService.get($stateParams.id).then(function(response){
        $scope.employee = response.data;
    });

    $scope.rows = 8;
    $scope.amountOf = function(num) {
        return new Array(num);
    };

    // Calculates total hours worked
    $scope.getTotal = function() {
        var total = 0;

        for (var i = 0; i < $scope.rows; i++) {
            if($scope.time.time[i]) {
                total = total += $scope.time.time[i];
            }
            if($scope.time.travel[i]) {
                total = total += $scope.time.travel[i];
            }
        }

        return total;
    };

    // Initialize array type
    $scope.time = [];
    $scope.time.orderId = {};
    $scope.time.description = {};
    $scope.time.time = {};
    $scope.time.travel = {};
    $scope.time.track = {};

    // Get preSavedOrders from database
    TimeService.get('employees', $stateParams.id, moment().format('YYYY-MM-D'), moment().format('YYYY-MM-D')).then(function(response){
        for (var i = 0; i < response.data.length; i++) {
            $scope.time.orderId[i] = response.data[i].orderId + '|' + response.data[i].orderNr;
            $scope.time.description[i] = response.data[i].description;
            $scope.time.time[i] = parseFloat(response.data[i].duration);
            $scope.time.travel[i] = parseFloat(response.data[i].travel);
            $scope.time.track[i] = parseInt(response.data[i].id);
        }
    });

    $scope.validate = function() {
        // Initialize array for time objects
        $scope.registeredTimeArray = [];

        // Reset error counter
        $scope.errors = 0;
        // Remove error styling if there was any
        angular.element('.input-fields .form-group').removeClass('has-error');

        // Generate an array containing the amount of rows
        var rows = Array.apply(null, {length: $scope.rows}).map(Number.call, Number);

        // Loop through all items
        _.each(rows, function(num) {
            // Initialize object for coming information
            var registeredTimeObject = {};

            // Check if atleast one field is filled in, otherwise do not include in array
            if($scope.time.orderId[num] || $scope.time.description[num] || $scope.time.time[num] || $scope.time.travel[num]) {
                // If a row contains values atleast 3 fields have to be filled in.
                if($scope.time.orderId[num] && $scope.time.description[num] && $scope.time.time[num]) {
                    // Create objects with items that are always available
                    registeredTimeObject.orderId = $scope.time.orderId[num].split('|')[0];
                    registeredTimeObject.orderNr = $scope.time.orderId[num].split('|')[1];
                    registeredTimeObject.description = $scope.time.description[num];
                    registeredTimeObject.time = $scope.time.time[num];

                    // If the non-required fields contain information include it here
                    if($scope.time.travel[num]) {
                        registeredTimeObject.travel = $scope.time.travel[num];
                    }
                } else {
                    // Increase amount of errors
                    $scope.errors++;
                    // Create a styling element around the form-group that is missing some required fields
                    angular.element('.input-fields .form-group:eq(' + num + ')').addClass('has-error');
                }
                // Add the row (created object) to the existing array
                $scope.registeredTimeArray.push(registeredTimeObject);
            }
        }, $scope.time);

        // If no errors are found allow modal to open.
        if($scope.errors === 0) {
            // Show modal with filled in fields
            angular.element('#registerMakeSureModal').modal('show');
        }
    };

    $scope.submit = function() {
        // POST form to API
        $http({
            method: 'POST',
            url: 'http://jabekon.nl/urenregistratie/api/set/time.php',
            dataType: 'json',
            data: {
                track: $scope.time.track,
                orders: $scope.time.orderId,
                descriptions: $scope.time.description,
                time: $scope.time.time,
                travel: $scope.time.travel,
                employeeId: $stateParams.id
            },
            headers: {'Content-Type': 'application/json; charset=utf-8; x-www-form-urlencoded'}
        }).then(function successCallback(response) {
            // this callback will be called asynchronously
            // when the response is available
            console.log('succes:', response);
            angular.element('#registerMakeSureModal').modal('hide');
            $timeout(function () { $location.path('home'); }, 1000)

        }, function errorCallback(response) {
            // called asynchronously if an error occurs
            // or server returns response with an error status.
            console.log('failure:', response);
        });
    };

    OrderService.get().then(function(response){
        $scope.orders = response.data;
    });

    OrderService.get().then(function(response){
        $scope.orders = response.data;
    });

});
